import { useLocation, useNavigation } from '@remix-run/react';
import axios from 'axios';
import { useEffect } from 'react';
import { type ScriptDescriptor } from 'remix-utils/external-scripts';

import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';

export function hubspotScripts(): ScriptDescriptor[] {
  return marketing(getEnv()).registrationMarketingTrackingEnabled
    ? [{ src: '//js.hs-scripts.com/21790599.js', async: true, defer: true }]
    : [];
}

declare global {
  interface Window {
    _hsq?: [string, (string | object)?][];
  }
}

function hubspotIdentify(email: string) {
  window._hsq = window._hsq || [];
  window._hsq.push(['identify', { email }]);
}

function hubspotTrackPageView(relativeLocation: string) {
  window._hsq = window._hsq || [];
  window._hsq.push(['setPath', relativeLocation]);
  window._hsq.push(['trackPageView']);
}

export function HubspotIdentify(props: { email: string }) {
  useEffect(() => {
    if (!props.email) return;
    hubspotIdentify(props.email);
  }, [props.email]);

  return null;
}

export function HubspotTrackPageView() {
  const location = useLocation();
  const navigation = useNavigation();

  useEffect(() => {
    // Only track page views when load is completed
    if (navigation.state !== 'idle') return;
    const path = `${location.pathname}${location.search}`;
    hubspotTrackPageView(path);
  }, [location.pathname, location.search, navigation.state]);

  return null;
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
}

type hubspotTrackInstantQuoteFormSubmittedProps = {
  email: string;
  headcount: number | string;
} & (
  | {
      kind: 'gamePack';
      gamePackName: string;
    }
  | {
      kind: 'program';
      programName: string;
    }
);

export async function hubspotTrackInstantQuoteFormSubmitted(
  props: hubspotTrackInstantQuoteFormSubmittedProps
) {
  const url =
    'https://api.hsforms.com/submissions/v3/integration/submit/21790599/b497b25a-85d4-4f3f-b8b9-9482e053577e';

  const fields = [
    {
      objectTypeId: '0-1',
      name: 'email',
      value: props.email,
    },
    {
      objectTypeId: '0-1',
      name: 'headcount',
      value: props.headcount.toString(),
    },
  ];

  switch (props.kind) {
    case 'gamePack':
      fields.push({
        objectTypeId: '0-1',
        name: 'gamePackName',
        value: props.gamePackName,
      });
      break;
    case 'program':
      fields.push({
        objectTypeId: '0-1',
        name: 'programName',
        value: props.programName,
      });
      break;
  }

  const data = {
    submittedAt: Date.now(),
    fields,
    context: {
      // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      hutk: getCookie('hubspotutk'),
      pageUri: window.location.href,
      pageName: document.title,
    },
    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Luna Park to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Luna Park.',
          },
        ],
      },
    },
  };

  try {
    await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error submitting form to Hubspot', error);
  }
}

export function HubspotSuppression() {
  return (
    <>
      {/* 
                This is a workaround to prevent the form from being collected by Hubspot.
                Refer to https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/246957
              */}
      <label htmlFor='cc-num' className='hidden'></label>
      <input
        name='cc-num'
        className='hidden'
        defaultValue='HubspotCollectedFormsWorkaround'
        id='cc-num'
      />
    </>
  );
}
